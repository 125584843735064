import axios from 'axios';
import authService from '@/auth/authService';
// import router from '../router';
import store from '../store';

import Vue from 'vue';

export const HTTPFront = axios.create({
    baseURL: '/',
});

Vue.config.errorHandler = (err, vm, info) => {
    console.log('central errorHandler', { err, vm, info });
    // TODO: Perform any custom logic or log to server
};

const interceptor = async error => {
    const status = error?.response ? error.response.status : null;
    if (status === 401) {
        try {
            await authService.refreshToken();
            error.config.headers.Authorization = 'Bearer ' + store.getters.token;
            error.config.baseURL = undefined;
            return axios.request(error.config);
        } catch (err) {
            store.dispatch('manageExpiredRefreshToken');
        }
    }
    return Promise.reject(error);
};

// const httpClients = [HTTPFront, HTTPAuth];

[HTTPFront].forEach(httpClient => httpClient.interceptors.response.use(response => response, interceptor));
