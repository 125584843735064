import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import notifications from './plugins/notifications';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';

import VueYouTubeEmbed from 'vue-youtube-embed';

// import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-geosearch/dist/geosearch.css';

Vue.use(notifications);
Vue.use(VueYouTubeEmbed);
Vue.use(VueI18n);

Vue.config.productionTip = false;

const i18n = new VueI18n({
    locale: 'es-AR', // Establece el idioma predeterminado como español
});

new Vue({
    vuetify,
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app');
